
import {Options, Vue} from 'vue-class-component';
import Navigation from "@/components/Navigation.vue";
import Hero from "@/components/Hero.vue";
import Why from "@/components/Why.vue";
import Steps from "@/components/Steps.vue";
import Who from "@/components/Who.vue";
import Footer from "@/components/Footer.vue";
import Droplets from "@/components/Droplets.vue";

@Options({
    components: {
        Navigation, Hero, Why, Steps, Who, Footer, Droplets
    }
})
export default class App extends Vue {
}
