
import { Vue } from 'vue-class-component';

export default class Navigation extends Vue {

    // eslint-disable-next-line
    goto(event: any): void {
        const target = event.target;
        const element = document.querySelector(target.getAttribute('href'));
        const top = element.offsetTop;

        window.scrollTo(0, top);
    }
}
