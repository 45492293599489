<template>
    <div class="droplets">
        <a href="tel:0649826526">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>
        </a>
        <a href="mailto:JeugdbegeleidingIDP@outlook.com">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>
        </a>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Navigation extends Vue {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .droplets {
        position: fixed;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 40px;
        bottom: 25px;
        right: 25px;

        > a {
            background-color: #AC3162;
            color:white;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-top: 10px;

            &:hover {
                background-color: #c44175;
                transform: scale(.95);
                transition: transform .2s;
            }
        }
    }
</style>
