<template>
    <Hero></Hero>
    <Why></Why>
    <Steps></Steps>
    <Who></Who>
    <Footer></Footer>
    <Navigation></Navigation>
    <Droplets></Droplets>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Navigation from "@/components/Navigation.vue";
import Hero from "@/components/Hero.vue";
import Why from "@/components/Why.vue";
import Steps from "@/components/Steps.vue";
import Who from "@/components/Who.vue";
import Footer from "@/components/Footer.vue";
import Droplets from "@/components/Droplets.vue";

@Options({
    components: {
        Navigation, Hero, Why, Steps, Who, Footer, Droplets
    }
})
export default class App extends Vue {
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Lato&display=swap');

#app {
    font-family: Lato, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    position: relative;

    height: 100%;
    background-color: #f7f7f7;
}

p,h1,h2,h3{
    margin:0;
}

html,body {
    margin:0;
    padding:0;
    height: 100%;
}

html {
    overflow: auto;
}

body {
    position: relative;
}

</style>
