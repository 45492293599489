<template>
    <div class="footer" id="gegevens">
        <div class="footer__wrapper">
            <div>
                <h3>
                    Meer weten?
                </h3>
                <ul>

                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z"/></svg>
                        <div>
                            Jacob Catsstraat 39
                            <br>
                            Castricum 1901 AN
                        </div>
                    </li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>
                        <a href="tel:0649826526">0649826526</a>
                    </li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>
                        <a href="mailto:JeugdbegeleidingIDP@outlook.com">JeugdbegeleidingIDP@outlook.com</a>
                    </li>

                    <li>BTW: NL003625417B83</li>
                    <li>KVK: 81959443</li>
                </ul>
            </div>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d538.86199199893!2d4.659949950959447!3d52.55064504966265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5f714cdacea11%3A0x3e4f264850d3958f!2sJacob%20Catsstraat%2039%2C%201901%20AN%20Castricum!5e0!3m2!1sen!2snl!4v1627679614881!5m2!1sen!2snl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Footer extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .footer {
        background-color: #4CBB85;
        margin-top: 40px;

        &__wrapper {
            display: grid;
            grid-template-columns: 1fr;
            min-height: 300px;
            margin: 0 auto;

            ul {
                list-style: none;
                padding: 0;

                li {
                    display: flex;
                    align-items: center;
                    padding:5px;
                    padding-left:0;
                }

                svg {
                    margin-right: 10px;
                }

                a {
                    color: white;
                    text-decoration: none;
                }
            }

            > div {
                color:white;
                padding:40px;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .footer {

            &__wrapper {
                max-width: 1080px;
                grid-template-columns: 2fr 1fr;
            }
        }
    }
</style>
