<template>
    <div class="steps" id="hoe">
        <div class="steps__header">
            <div><h2>Hoe?</h2></div>
        </div>
        <div class="steps__wrapper">
            <div class="steps__counter">
                <span>
                    1.
                </span>
            </div>
            <div class="steps__text">
                - In de eerste plaats door te luisteren, vanuit een open houding;<br><br>

                - Wat wil de jeugdige bereiken?<br><br>

                · Ik ga in gesprek met  zijn/haar omgeving, en vraag waar hij/zij aan wil werken, we stellen een doel;<br><br>

                - Ik maak een plan van aanpak waarin ik omschrijf hoe we dat doel kunnen gaan bereiken;<br><br>

                · Waar liggen de mogelijkheden, waarbij rekening wordt gehouden met de draaglast en de draagkracht van de jeugdige op dat moment. Welke praktische handvatten zetten we in;<br><br>

                · Wat kan de omgeving (school, gezin, sociale contacten) aan het doel bijdragen en wat is daar voor nodig.
            </div>
            <div class="steps__counter">
                <span>
                    2.
                </span>
            </div>
            <div class="steps__text">
                In de tweede plaats door balans in het denken van de jeugdige te brengen. Elk mens heeft zijn emoties met daarbij de valkuilen. Voor mensen met ASS zijn deze vaak moeilijker te begrijpen, vertalen en te reguleren. Daarom vind ik het belangrijk enige zelfreflectie aan te leren en zo de zelfredzaamheid te vergroten.<br><br>

                - Een leer- effect: wat doet het gedrag van een ander bij mij, en waarom reageert iemand zo op mijn gedrag?<br>
                - Hoe kan ik beter (ontspanning) omgaan met de emoties die daarbij komen kijken?<br>
                - Kan ik het de volgende keer misschien anders doen?<br><br>

                Dit kan er aan bijdragen dat nieuwe of andere situaties zelfverzekerder en adequater worden aangepakt. Er ontstaat groei naar de eigen kwaliteiten!
            </div>
            <div class="steps__counter">
                <span>
                    3.
                </span>
            </div>
            <div class="steps__text">
                Natuurlijk kan ik hierin een rol spelen door het gesprek met school of instanties samen te voeren. Uit ervaring weet ik dat deze gesprekken niet altijd makkelijk zijn.<br><br>

                Dat is meestal geen onwil. De theorie is wel bekend maar komt niet altijd overeen met de praktijk, die bij elk individu anders is en niet in boekjes staat.<br>
                Vaak ontbreekt de praktische ervaring en het begrip over de belevingswereld van mensen met ASS. Door extra uitleg wordt het voor hen makkelijker die belevingswereld positief tegemoet te treden en te denken in mogelijkheden.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Steps extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .steps {
        width: 100%;
        background-color: #f7f7f7;
        overflow: hidden;

        &__header {
            background-color: white;

            div{
                width: 1080px;
                margin:0 auto;
            }

            h2 {
                width: 520px;
                background-color: #4CBB85;
                color:white;
                padding:10px 40px;
                box-sizing: border-box;
                box-shadow: 0 0 10px -2px rgba(143, 130, 130, 0.3);
            }
        }

        &__wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap:40px;
            align-items: center;
            justify-items: center;
            padding: 40px;
            box-sizing: border-box;
            margin: 0 auto;
        }

        &__text {
            background-color: #EBEBEB;
            padding: 40px;
            border-radius: 10px;
            box-shadow: 0 0 10px -2px rgba(143, 130, 130, 0.3);
        }

        &__counter{
            span {
                background-color: #4CBB85;
                border-radius: 50%;
                display: block;
                width:150px;
                height: 150px;
                text-align: center;
                line-height: 150px;
                font-size: 40px;
                color:white;
                box-shadow: 0 0 10px -2px rgba(143, 130, 130, 0.3);
            }
        }
    }



    @media screen and (min-width: 768px) {
        .steps {
            &__wrapper{
                justify-items: start;
                grid-template-columns: 75px 1fr;
                max-width: 1080px;
            }

            &__header{
                h2 {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }
        }
    }
</style>
