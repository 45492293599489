<template>
    <div class="who" id="waaromik">
        <div class="who__wrapper">
            <h2 class="who__title">
                Waarom ik?
            </h2>
            <div class="who__content">
                <div>
                    Vanuit ervaring, en later het behalen van het diploma coaching bij autisme, heb ik geleerd dat het begeleiden van mensen met autisme een andere kijk op denken vraagt.<br><br>

                    Zo kan ik kinderen en jongeren met een milde vorm van autisme op de manier ondersteunen die past bij hun belevingswereld.<br><br>

                    Dit maatwerk wil ik graag bieden.
                </div>
                <figure>
                    <div></div>
                </figure>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Who extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .who {
        &__wrapper {
            box-shadow: 0 0 10px -2px rgba(143, 130, 130, 0.3);
        }

        &__title {
            background-color: #4CBB85;
            color:white;
            padding:10px 40px;
        }

        &__content {
            display: grid;
            grid-template-columns: 1fr;
            background-color: white;

            > div {
                padding: 20px 40px 40px;
            }

            > figure {
                display: flex;
                align-items: center;
                justify-content: center;

                div {
                    width: 200px;
                    height: 200px;
                    background-image: url("/trees.jpeg");
                    background-size: cover;
                    box-shadow: 0 0 10px -2px rgba(143, 130, 130, 0.3);
                    border-radius: 50%;
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        .who {
            padding-top:40px;

            &__wrapper{
                max-width: 1080px;
                margin: 0 auto;
            }

            &__title {
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }

            &__content {
                grid-template-columns: 1fr 1fr;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
</style>
